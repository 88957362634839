@font-face {
  font-family: "Roca Light";
  src: url("./Fonts/Roca-Font/RocaLight.ttf");
}
@font-face {
  font-family: "Roca Bold";
  src: url("./Fonts/Roca-Font/RocaBold.ttf");
}
@font-face {
  font-family: "Roca Bold Italic";
  src: url("./Fonts/Roca-Font/RocaBoldItalic.ttf");
}

@font-face {
  font-family: "Avenir Extra Bold";
  src: url("./Fonts/Avenir-next-font/AvenirNextLTPro-Bold.otf");
}

@font-face {
  font-family: "Avenir Roman";
  src: url("./Fonts/Avenir-font/AvenirLTStd-Roman.otf");
}

@font-face {
  font-family: "Recoleta Light";
  src: url("./Fonts/RecoletaLight/font.woff");
}

@font-face {
  font-family: "Recoleta SemiBold";
  src: url("./Fonts/RecoletaSemiBold/font.woff");
}

em {
  font-style: normal;
  color: #ff6900;
}

.block-link {
  text-decoration: none;
}

a {
  /* text-decoration: none; */
  color: unset;
  line-height: 1;
}

a:visited {
  color: unset;
}

iframe {
  border: none;
}
